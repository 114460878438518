// Generated by Framer (3eae0e1)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Image from "https://framerusercontent.com/modules/4FYXL5lQz9cgJ2BJkaNV/73UsIpa97froefZwqzz9/Image.js";
const ImageFonts = getFonts(Image);

const serializationHash = "framer-54v11"

const variantClassNames = {ovVyo3F4R: "framer-v-d01a5s"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({alt, description, height, id, image, width, ...props}) => { return {...props, b63X5ss7j: description ?? props.b63X5ss7j, fMZTI0L2V: image ?? props.fMZTI0L2V, HH4Vp_tcA: alt ?? props.HH4Vp_tcA} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};alt?: string;description?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fMZTI0L2V, HH4Vp_tcA, b63X5ss7j, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ovVyo3F4R", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-d01a5s", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ovVyo3F4R"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1iltbmd-container"} layoutDependency={layoutDependency} layoutId={"q1N11imkr-container"}><Image alt={HH4Vp_tcA} height={"100%"} id={"q1N11imkr"} image={toResponsiveImage(fMZTI0L2V)} layoutId={"q1N11imkr"} newTab padding={0} radius={0} shadowOptions={{shadowBlur: 4, shadowColor: "rgba(0, 0, 0, 0.2)", shadowEnabled: false, shadowX: 0, shadowY: 2}} style={{width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtMzAw", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "11px", "--framer-font-weight": "300", "--framer-line-height": "1.1em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-dda86418-62d7-4975-8ae2-0326e59e9f2a, rgb(24, 24, 24)))"}}>Content</motion.p></React.Fragment>} className={"framer-1kr4joc"} fonts={["GF;Inter Tight-300"]} layoutDependency={layoutDependency} layoutId={"EtVgN9qd6"} style={{"--extracted-r6o4lv": "var(--token-dda86418-62d7-4975-8ae2-0326e59e9f2a, rgb(24, 24, 24))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={b63X5ss7j} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-54v11.framer-1dijy1h, .framer-54v11 .framer-1dijy1h { display: block; }", ".framer-54v11.framer-d01a5s { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; padding: 0px; position: relative; width: 649px; }", ".framer-54v11 .framer-1iltbmd-container { flex: none; height: auto; position: relative; width: 100%; }", ".framer-54v11 .framer-1kr4joc { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-54v11.framer-d01a5s { gap: 0px; } .framer-54v11.framer-d01a5s > * { margin: 0px; margin-bottom: calc(5px / 2); margin-top: calc(5px / 2); } .framer-54v11.framer-d01a5s > :first-child { margin-top: 0px; } .framer-54v11.framer-d01a5s > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 978
 * @framerIntrinsicWidth 649
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"fMZTI0L2V":"image","HH4Vp_tcA":"alt","b63X5ss7j":"description"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerXDJhJM7kg: React.ComponentType<Props> = withCSS(Component, css, "framer-54v11") as typeof Component;
export default FramerXDJhJM7kg;

FramerXDJhJM7kg.displayName = "Core/ImageConverter";

FramerXDJhJM7kg.defaultProps = {height: 978, width: 649};

addPropertyControls(FramerXDJhJM7kg, {fMZTI0L2V: {description: "", title: "Image", type: ControlType.ResponsiveImage}, HH4Vp_tcA: {defaultValue: "", title: "Alt", type: ControlType.String}, b63X5ss7j: {defaultValue: "", title: "description", type: ControlType.String}} as any)

addFonts(FramerXDJhJM7kg, [{explicitInter: true, fonts: [{family: "Inter Tight", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjUQ-qWSRToK8EPg.woff2", weight: "300"}]}, ...ImageFonts], {supportsExplicitInterCodegen: true})